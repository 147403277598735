@import "minima";

/* Default css file for jemdoc. */
/* from jemdoc */
body {
	background: white;
	font-family: Georgia, "PT Sans", "Noto Sans SC", "Microsoft YaHei", sans-serif;;
	padding-bottom: 8px;
	margin: 0;
}


h1, h2, h3 {
	color: #527bbd;
	margin-bottom: 0.3em;
	padding-bottom: 0.2em;
	line-height: 1.0;
	border-bottom: 1px solid #aaaaaa;
  text-transform:capitalize;
}

h1 {
	font-size: 165%;
}

h2 {
	padding-top: 0.8em;
	font-size: 125%;
}

h2 + h3 {
    padding-top: 0.2em;
}

h3 {
	font-size: 110%;
	border-bottom: none;
}

p {
	margin-top: 0.0em;
	margin-bottom: 0.8em;
	padding: 0;
	line-height: 1.3;
}

ul, ol, dl {
	margin-top: 0.2em;
	padding-top: 0;
	margin-bottom: 0.8em;
}

dt {
	margin-top: 0.5em;
	margin-bottom: 0;
}

dl {
	margin-left: 20px;
}

dd {
	color: #222222;
}

dd > *:first-child {
	margin-top: 0;
}

ul {
	list-style-position: outside;
	list-style-type: square;
}

p + ul, p + ol {
	margin-top: -0.5em;
}

li ul, li ol {
	margin-top: -0.3em;
}

ol {
	list-style-position: outside;
	list-style-type: decimal;
}

li p, dd p {
	margin-bottom: 0.3em;
}


ol ol {
	list-style-type: lower-alpha;
}

ol ol ol {
	list-style-type: lower-roman;
}
